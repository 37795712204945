<template>
  <v-dialog v-model="showDialog" persistent max-width="450px">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Tên đề"
                  dense
                  :rules="[v => !!v || 'Bạn chưa nhập tên đề']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-if="!editing"
                  v-model="form.examinationSubjectId"
                  label="Kíp thi môn học"
                  :items="options.examinationSubjects"
                  :item-text="
                    item =>
                      item.examination.name + ' - ' + item.subject.fullName
                  "
                  item-value="id"
                  dense
                  :rules="[v => !!v || 'Bạn chưa chọn kíp thi môn học']"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn v-if="!editing" color="blue darken-1" text @click="createData"
          >Thêm</v-btn
        >
        <v-btn v-else color="blue darken-1" text @click="updateData"
          >Cập nhật</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { store, update } from "@/api/original-exam";
import dialogMixin from "@/mixins/crud/dialog";

export default {
  mixins: [dialogMixin(store, update)],
  computed: {
    title() {
      return !this.editing ? "Thêm mới" : "Cập nhật";
    }
  },
  data() {
    return {
      types: [
        { id: "M", text: "Giữa kì" },
        { id: "F", text: "Cuối kì" }
      ]
    };
  },
  methods: {
    async updateData() {
      try {
        await this.$refs.form.validate();

        if (!this.valid) return;

        for (const field in this.form) {
          if (field !== "id") {
            if (this.form[field] === undefined) this.form[field] = null;
          }
        }
        this.loading = true;
        await update(this.form.id, { name: this.form.name });
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
